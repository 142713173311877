<template>
  <v-card class="neu-glow manuals-tree">
    <v-card-title class="primary white--text text-h6" style="padding: 8px">
      <v-row class="mx-0"
        >Inventario de Manuales
        <v-spacer />
        <div>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                class="mr-1"
                v-bind="attrs"
                v-on="on"
                color="#adadb0"
                dark
                @click.prevent="
                  $router.push({ name: 'administrative/human-talent/Versions' })
                "
                >Versiones
              </v-btn>
            </template>
            <span>Administrar Versiones</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                v-bind="attrs"
                v-on="on"
                color="#adadb0"
                dark
                @click.prevent="handleCreate()"
                >Crear
              </v-btn>
            </template>
            <span>Crear Manual</span>
          </v-tooltip>
        </div>
      </v-row>
    </v-card-title>
    <v-row class="mx-0" justify="space-between">
      <v-col md="5" cols="12" class="mt-2">
        <v-text-field
          v-model="search"
          label="Buscar"
          hide-details
          clearable
          clear-icon="mdi-close-circle-outline"
        />
        <v-treeview
          class="treeview"
          dense
          :active.sync="active"
          :items="items"
          :load-children="getChildrenManuals"
          :open.sync="open"
          :search="search"
          :filter="filter"
          :return-object="true"
          shaped
          hoverable
          activatable
          color="primary"
          selected-color="primary"
          transition
          open-all
          @update:active="getSelectedManual($event)"
        >
          <template v-slot:append="{ item }">
            <v-menu offset-y class="options-menu" open-on-hover>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="mr-1" icon x-small v-bind="attrs" v-on="on"
                  ><v-icon>mdi-file-edit-outline</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item class="px-1" @click="handleEdit(item)">
                  <v-list-item-icon style="margin: 0px">
                    <v-btn class="ma-2" x-small inline fab color="primary"
                      ><v-icon>mdi-pencil-outline</v-icon></v-btn
                    >
                  </v-list-item-icon>
                  <v-list-item-content style="padding: 0px">
                    Editar Manual
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="px-1" @click="deleteItem(item)">
                  <v-list-item-icon style="margin: 0px">
                    <v-btn class="ma-2" x-small inline fab color="red"
                      ><v-icon>mdi-close</v-icon></v-btn
                    >
                  </v-list-item-icon>
                  <v-list-item-content style="padding: 0px">
                    Eliminar Manual
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-treeview>
      </v-col>

      <v-col md="7" cols="12" class="d-flex">
        <v-divider vertical />
        <v-scroll-y-transition mode="out-in">
          <div
            v-if="!selected.name"
            class="text-h6 grey--text text--lighten-1 font-weight-light"
            style="align-self: center"
          >
            <v-card-text><h3>Seleccione un manual</h3> </v-card-text>
          </div>
          <v-card ref="" v-else :key="selected.id" class="mx-auto py-4" width="100%" flat>
            <div v-if="selected.lastContent" :key="selectedIndex">
              <v-card-text style="padding-top: 0px">
                <h3 class="mb-2">
                  {{ selected.name }} -
                  {{ selected.lastContent.name }}
                </h3>
                <iframe
                  v-if="
                    selected.lastContent.uploads &&
                    selected.lastContent.uploads.length > 0 &&
                    (selected.lastContent.format === 'pdf' ||
                      selected.lastContent.format === 'docx' ||
                      selected.lastContent.format === 'xlsx' ||
                      selected.lastContent.format === 'pptx')
                  "
                  :src="
                    'https://docs.google.com/gview?url=' +
                    selected.lastContent.uploads[0].route +
                    '&embedded=true'
                  "
                  style="width: 100%; height: 500px"
                  frameborder="0"
                />
                <iframe
                  v-else-if="selected.lastContent.format === 'video'"
                  width="100%"
                  height="300"
                  :src="selected.lastContent.url"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                />
              </v-card-text>
              <v-subheader>Formato: {{ selected.type.name }}</v-subheader>
              <v-subheader
                >Última modificación: {{ selected.lastContent.date }}</v-subheader
              >
              <v-subheader
                >Autor: {{ selected.lastContent.user | toUserFullName }}</v-subheader
              >
              <v-subheader
                >Tipo de contenido: {{ selected.lastContent.format }}</v-subheader
              >
            </div>

            <v-card max-width="450" class="mx-auto mt-4" tile v-if="selected.name">
              <v-toolbar flat color="grey lighten-4" style="border: 1px solid #000">
                <v-toolbar-title
                  ><small>Versiones de "{{ selected.name }}"</small>
                </v-toolbar-title>
                <v-spacer />
                <v-btn @click="handleCreateContent()" icon>
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-toolbar>

              <v-list v-if="selected.contents && selected.contents.length > 0">
                <v-list-item v-for="(item, index) in selected.contents" :key="index">
                  <v-list-item-icon style="margin: 0px" class="d-flex align-self-center">
                    <v-btn
                      v-if="selected.contents.length > 1"
                      class="ma-1"
                      x-small
                      inline
                      @click="setLastContent(item)"
                      fab
                      color="primary"
                      ><v-icon>mdi-feature-search</v-icon></v-btn
                    >
                    <v-btn
                      class="ma-1"
                      x-small
                      inline
                      @click="handleEditContent(item)"
                      fab
                      color="primary"
                      ><v-icon>mdi-pencil-outline</v-icon></v-btn
                    >
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-html="item.name" />
                    <span
                      ><small>{{ item.version.name }}</small></span
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-divider />
              </v-list>
              <v-list v-else>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      >Aún no haz creado contenidos para este manual</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-card>
        </v-scroll-y-transition>
      </v-col>
    </v-row>
    <v-row justify="center">
      <modal name="crudModal" :height="'80%'" :width="'90%'" :adaptive="true">
        <v-card class="pa-4" elevation="0">
          <h2 class="text-center mb-4" v-if="flow === 'create'">Crear Manual</h2>
          <h2 class="text-center mb-4" v-if="flow === 'edit'">Editar Manual</h2>

          <span v-if="flow === 'edit' && item.created_at"
            >Fecha de creación: {{ toLocaleDateString(createdAt, 'es-VE') }}.
            {{ dateRange > 0 ? 'Hace ' + dateRange + ' dias' : '' }}</span
          >
          <v-form ref="formCompany" v-model="valid" style="width: 100%">
            <v-row class="mx-0">
              <v-col cols="12" lg="4" md="6">
                <v-row style="margin: 0px">
                  <label style="margin: 0px auto; font-size: 13px; width: 20%"
                    >Tipo</label
                  >
                  <multiselect
                    style="width: 80%"
                    track-by="id"
                    label="name"
                    placeholder="Seleccionar"
                    v-model="type"
                    :options="manualTypes"
                    :allow-empty="false"
                    :multiple="false"
                    :close-on-select="true"
                    :clear-on-select="true"
                    :preserve-search="false"
                  />
                </v-row>
              </v-col>
              <v-col cols="12" sm="6">
                <v-row style="margin: 0px">
                  <label style="margin: 0px auto; font-size: 13px; width: 40%"
                    >Manual Principal</label
                  >
                  <multiselect
                    style="width: 60%"
                    track-by="id"
                    label="name"
                    placeholder="Seleccionar"
                    v-model="manualId"
                    :options="manuals"
                    :multiple="false"
                    :close-on-select="true"
                    :clear-on-select="true"
                    :preserve-search="false"
                  />
                </v-row>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  counter
                  required
                  :rules="[rules.required, rules.max]"
                  label="Nombre"
                  v-model="name"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card>
        <v-col cols="12" style="margin-bottom: 20px; height: 10%">
          <div class="row">
            <v-btn
              small
              style="margin: auto; margin-top: 10px"
              v-if="flow === 'create'"
              :disabled="!valid"
              color="primary"
              @click="createItem()"
            >
              Crear
            </v-btn>
            <v-btn
              small
              style="margin: auto; margin-top: 10px"
              v-if="flow === 'edit'"
              :disabled="!valid"
              color="primary"
              @click="saveItem()"
            >
              Guardar
            </v-btn>

            <v-btn
              small
              style="margin: auto; margin-top: 10px"
              color="primary"
              @click="reset"
            >
              Limpiar
            </v-btn>
            <v-btn
              small
              style="margin: auto; margin-top: 10px"
              color="primary"
              @click="$modal.hide('crudModal')"
            >
              Cancelar
            </v-btn>
          </div>
        </v-col>
      </modal>
      <modal name="contentModal" :height="'80%'" :width="'90%'" :adaptive="true">
        <v-card class="pa-4" elevation="0">
          <h2 class="text-center" v-if="flowContent === 'create'">
            Crear versión de {{ selected.name }}
          </h2>
          <h2 class="text-center" v-if="flowContent === 'edit'">
            Editar versión de {{ selected.name }}
          </h2>

          <span v-if="flowContent === 'edit' && item.created_at"
            >Fecha de creación: {{ toLocaleDateString(createdAt, 'es-VE') }}.
            {{ dateRange > 0 ? 'Hace ' + dateRange + ' dias' : '' }}</span
          >
          <v-form ref="formContents" v-model="validContent" style="width: 100%">
            <v-row>
              <v-col cols="12" lg="4" md="6" sm="12">
                <v-row style="margin: 0px">
                  <label style="margin: 0px auto; font-size: 13px; width: 20%"
                    >Versión</label
                  >
                  <multiselect
                    style="width: 80%"
                    track-by="id"
                    label="name"
                    placeholder="Seleccionar"
                    v-model="content.version"
                    :options="versions"
                    :allow-empty="false"
                    :multiple="false"
                    :close-on-select="true"
                    :clear-on-select="true"
                    :preserve-search="false"
                  />
                </v-row>
              </v-col>
              <v-col cols="12" lg="4" md="6">
                <v-text-field
                  counter
                  required
                  :rules="[rules.required, rules.max]"
                  label="Nombre"
                  v-model="content.name"
                />
              </v-col>
              <v-col cols="12" lg="4" md="6" sm="12">
                <v-row style="margin: 0px">
                  <label style="margin: 0px auto; font-size: 13px; width: 20%"
                    >Formato</label
                  >
                  <multiselect
                    style="width: 80%"
                    track-by="id"
                    label="name"
                    placeholder="Seleccionar"
                    v-model="content.format"
                    :options="contentFormats"
                    :allow-empty="false"
                    :multiple="false"
                    :close-on-select="true"
                    :clear-on-select="true"
                    :preserve-search="false"
                  />
                </v-row>
              </v-col>
              <v-col cols="12" lg="4" md="6">
                <v-text-field
                  counter
                  required
                  :rules="[rules.required]"
                  label="Fecha"
                  v-model="content.date"
                  type="date"
                />
              </v-col>
              <v-col
                cols="12"
                lg="4"
                md="6"
                v-if="content.format && content.format.id === 'video'"
              >
                <v-text-field
                  counter
                  required
                  :rules="[rules.required, rules.url]"
                  label="Enlace"
                  v-model="content.url"
                />
              </v-col>
              <v-col
                cols="12"
                lg="4"
                md="6"
                sm="12"
                v-if="content.format && content.format.id !== 'video'"
              >
                <div
                  class="row"
                  style="display: flex; justify-content: center; align-items: center"
                >
                  <img
                    v-if="
                      flowContent === 'edit' &&
                      selected.lastContent &&
                      selected.lastContent.format === 'image' &&
                      selected.lastContent.file
                    "
                    :src="selected.lastContent.file.route"
                    :alt="selected.lastContent.name"
                    style="width: 40%"
                  />
                  <v-tooltip
                    top
                    v-else-if="
                      flowContent === 'edit' &&
                      selected.lastContent &&
                      selected.lastContent.format !== 'image'
                    "
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        icon
                        style="width: 10%"
                        v-if="
                          selected.lastContent &&
                          selected.lastContent.format !== 'image' &&
                          selected.lastContent.file
                        "
                        ><a
                          :href="selected.lastContent.file.route"
                          target="_blank"
                          alt
                          download
                          ><v-icon>mdi-download</v-icon></a
                        ></v-btn
                      ></template
                    ><span>Descargar archivo</span></v-tooltip
                  >
                  <v-file-input
                    v-model="content.file"
                    chips
                    :rules="[rules.fileSize]"
                    ref="file"
                    show-size
                    :accept="permitedFormats"
                    :label="'Seleccione un archivo'"
                    style="width: 60%"
                  />
                </div>
              </v-col>
              <v-col cols="12" lg="4" md="6">
                <v-checkbox
                  :label="content.active ? 'Activo' : 'Inactivo'"
                  v-model="content.active"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card>
        <v-col cols="12" style="margin-bottom: 20px; height: 10%">
          <div class="row">
            <v-btn
              small
              style="margin: auto; margin-top: 10px"
              v-if="flowContent === 'create'"
              :disabled="!validContent"
              color="primary"
              @click="createContent()"
            >
              Crear
            </v-btn>
            <v-btn
              small
              style="margin: auto; margin-top: 10px"
              v-if="flowContent === 'edit'"
              :disabled="!validContent"
              color="primary"
              @click="saveContent()"
            >
              Guardar
            </v-btn>

            <v-btn
              small
              style="margin: auto; margin-top: 10px"
              color="primary"
              @click="reset()"
            >
              Limpiar
            </v-btn>
            <v-btn
              small
              style="margin: auto; margin-top: 10px"
              color="primary"
              @click="$modal.hide('contentModal')"
            >
              Cancelar
            </v-btn>
            <v-btn
              small
              style="margin: auto; margin-top: 10px"
              color="red darken-2"
              v-if="flowContent === 'edit'"
              dark
              @click="deleteContent(content)"
            >
              Eliminar
            </v-btn>
          </div>
        </v-col>
      </modal>
    </v-row>
  </v-card>
</template>
<script>
  import { decryptData } from '@/utils/encryption'
  import { toLocaleDateString } from '@/utils/helpers'
  const pause = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

  export default {
    props: {
      typeId: {
        type: Number,
        default: 1,
      },
    },
    data() {
      return {
        config: {},
        flow: '',
        flowContent: '',
        selectedIndex: 1,
        tab: 0,
        action: 0,
        active: [],
        createdAt: '',
        toDay: new Date(),
        dateRange: '',
        manuals: [{ id: 0, name: 'No posee' }],
        manualsTree: [],
        types: [],
        versions: [],
        dialog: false,
        valid: true,
        validContent: true,
        open: [],
        item: {},
        selected: {},
        orderBy: { id: 1, name: 'Recientes', order: 'created_at', by: 'desc' },
        orderOptions: [
          { id: 1, name: 'Recientes', order: 'created_at', by: 'desc' },
          { id: 2, name: 'Antiguos', order: 'created_at', by: 'asc' },
          { id: 3, name: 'Nombre', order: 'name', by: 'asc' },
          { id: 4, name: 'Última Edición', order: 'updated_at', by: 'desc' },
        ],
        search: null,
        caseSensitive: false,
        name: '',
        format: '',
        manualId: '',
        users: [],
        content: {
          id: '',
          format: { id: 'pdf', name: 'PDF' },
          name: '',
          type: { id: 1, name: 'Manual' },
          active: true,
          url: '',
          date: new Date().toISOString().split('T')[0],
          version: null,
          file: null,
        },
        contentFormats: [
          { id: 'xlsx', name: 'Hoja de Calculo' },
          { id: 'pptx', name: 'Presentación' },
          { id: 'docx', name: 'Documento de texto' },
          { id: 'pdf', name: 'PDF' },
          { id: 'video', name: 'Video' },
          { id: 'image', name: 'Imagen' },
        ],
        manualTypes: [
          { id: 0, name: 'Manual' },
          { id: 1, name: 'Proceso' },
          { id: 2, name: 'Procedimiento' },
        ],
        rules: {
          required: (v) => !!v || 'Requerido.',
          min: (v) => (v ? v.length >= 8 : true) || '8 caracteres como mínimo',
          max: (v) =>
            (v ? v.length <= 100 : true) || 'Debe poser menos de 200 caracteres',
          maxNumber: (v) =>
            (v ? v.length <= 18 : true) || 'Debe poser menos de 18 caracteres',
          url: (v) => (v ? this.isURL(v) : true) || 'La URL es inválida',
          email: (v) => /.+@.+\..+/.test(v) || 'E-mail debe ser válido',
          fileSize: (v) =>
            (v ? v.size < 5120000 : true) ||
            'El peso del archivo debe ser inferior a 5MB',
        },
        type: { id: 0, name: 'Manual' },
      }
    },
    created() {
      this.getMainManuals()
      this.getTypes()
      this.getVersions()
      this.getManuals()
    },

    computed: {
      items() {
        return this.manualsTree
      },
      filter() {
        return this.caseSensitive
          ? (item, search, textKey) => item[textKey].indexOf(search) > -1
          : undefined
      },
      permitedFormats() {
        if (this.content.format && this.content.format.id) {
          switch (this.content.format.id) {
            case 'image':
              return 'image/*'
            case 'docx':
              return '.docx'
            case 'xlsx':
              return '.xlsx'
            case 'pptx':
              return '.pptx'
            default:
              return '.pdf'
          }
        } else {
          return '.pdf'
        }
      },
    },
    filters: {
      toUserFullName(user) {
        if (!user) return ''
        return `${user.name} ${user.lastname}`
      },
    },
    methods: {
      toLocaleDateString: toLocaleDateString,
      isURL(str) {
        let url
        try {
          url = new URL(str)
        } catch (_) {
          return false
        }

        return url.protocol === 'http:' || url.protocol === 'https:'
      },
      validate() {
        this.$refs.formCompany.validate()
        this.$refs.formContents.validate()
      },

      reset() {
        this.$refs.formCompany.reset()
        this.$refs.formContents.validate()
      },
      async getMainManuals() {
        let searchStr = ''
        let orderString = ''
        if (this.orderBy.order && this.orderBy.by) {
          orderString = '&order=' + this.orderBy.order + '&by=' + this.orderBy.by
        }
        if (this.search && this.search !== '') {
          searchStr = '&search=' + this.search
        }
        this.$axios
          .get(
            'manuals?limit=100&children=1&manual_id=0&type_id=' +
              this.typeId +
              searchStr +
              orderString
          )
          .then((response) => {
            this.manualsTree = response.data
            this.manualsTree.forEach((manual) => {
              if (manual.children.length === 0) {
                delete manual.children
              } else if (manual.children && manual.children.length > 0) {
                manual.children.forEach((manual2) => {
                  if (manual2.children && manual2.children.length === 0) {
                    delete manual2.children
                  }
                })
              }
            })
          })
          .catch((err) => console.warn(err))
      },

      setLastContent(content) {
        this.selected.lastContent = content
        this.selectedIndex++
      },

      async getChildrenManuals(item) {
        if (item.has_children) {
          await pause(2000)
          return this.$axios
            .get('manuals?limit=100&children=1&manual_id=' + item.id)
            .then((response) => {
              item.children.push(...response.data)
              item.chindren.forEach((manual) => {
                if (manual.children && manual.children.length === 0) {
                  delete manual.children
                } else if (manual.children && manual.children.length > 0) {
                  manual.children.forEach((manual2) => {
                    if (manual2.children && manual2.children.length === 0) {
                      delete manual2.children
                    }
                  })
                }
              })
            })
            .catch((err) => console.warn(err))
        }
      },

      getSelectedManual(manual) {
        if (manual.length > 0) {
          this.$axios
            .get(
              'manuals/' +
                manual[0].id +
                '?with[]=contents.user&with[]=contents.version&with[]=contents.uploads&with[]=type'
            )
            .then((response) => {
              this.selected = response.data
              this.selected.contents.forEach((content) => {
                content.file = content.uploads[0]
              })
              this.selected.lastContent = this.selected.contents[0]
            })
        }
      },

      async getTypes(search) {
        let searchStr = ''
        if (search) {
          searchStr = '&search=' + search
        }
        this.$axios.get('types?limit=100' + searchStr).then((response) => {
          this.types = response.data
        })
      },

      async getManuals(search) {
        let searchStr = ''
        if (search) {
          searchStr = '&search=' + search
        }
        this.$axios.get('manuals?limit=100' + searchStr).then((response) => {
          this.manuals = [{ id: 0, name: 'No posee' }]
          this.manuals.push(...response.data)
        })
      },

      async getVersions(search) {
        let searchStr = ''
        if (search) {
          searchStr = '&search=' + search
        }
        this.$axios.get('versions?limit=100' + searchStr).then((response) => {
          this.versions = response.data
        })
      },

      handleOrder(orderBy) {
        this.orderBy = orderBy
        this.getMainManuals()
      },

      async handleCreate() {
        this.flow = 'create'
        this.item = {}
        this.name = ''
        this.type = { id: 0, name: 'Manual' }
        this.manualId = null

        this.$modal.show('crudModal')
      },

      async handleCreateContent() {
        this.flowContent = 'create'
        this.content.version = this.versions.length > 0 ? this.versions.shift() : null
        this.content.name = ''
        this.content.format = { id: 'pdf', name: 'PDF' }
        this.content.type = { id: 1, name: 'Manual' }
        this.content.url = ''
        this.content.date = new Date().toISOString().split('T')[0]
        this.content.active = true

        this.$modal.show('contentModal')
      },

      async handleEditContent(item) {
        this.flowContent = 'edit'
        this.content.id = item.id
        this.content.version = item.version
        this.content.name = item.name
        this.content.format = this.contentFormats.find(
          (format) => format.id === item.format
        )
        this.content.type = this.manualTypes.find((type) => type.id === item.type)
        this.content.url = item.url
        this.content.date = new Date(item.date).toISOString().split('T')[0]
        this.content.active = item.active
        this.createdAt = new Date(item.created_at)
        this.dateRange = Math.ceil(
          Math.abs(this.createdAt - this.toDay) / (1000 * 60 * 60 * 24)
        )

        this.$modal.show('contentModal')
      },

      async handleEdit(item) {
        this.flow = 'edit'
        this.item = item
        this.name = this.item.name
        this.type = this.manualTypes.find((type) => type.id === item.type)
        this.manualId = this.manuals.find((manual) => manual.id === this.item.manual_id)
        this.createdAt = new Date(this.item.created_at)
        this.dateRange = Math.ceil(
          Math.abs(this.createdAt - this.toDay) / (1000 * 60 * 60 * 24)
        )

        this.$modal.show('crudModal')
      },

      async createItem() {
        this.$axios
          .post('manuals', {
            type_id: this.typeId,
            manual_id: this.manualId.id,
            type: this.type.id,
            name: this.name,
          })
          .then(() => {
            this.page = 1
            this.selectedIndex++
            this.getMainManuals()
            this.getManuals()
            this.$modal.hide('crudModal')
          })
      },

      async saveItem() {
        this.$axios
          .patch('manuals/' + this.item.id, {
            type_id: this.typeId,
            manual_id: this.manualId.id,
            type: this.type.id,
            name: this.name,
          })
          .then(() => {
            this.page = 1
            this.selectedIndex++
            this.getMainManuals()
            this.getManuals()
            this.$modal.hide('crudModal')
          })
      },

      async deleteItem(item) {
        this.$swal({
          title: '¿Estás seguro?',
          text:
            'Eliminarás esta información y todas sus dependencias de forma permanente',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonText: 'Cancelar',
          confirmButtonText: 'Si, eliminar',
        }).then((result) => {
          if (result.isConfirmed) {
            this.$axios.delete('manuals/' + item.id).then(() => {
              this.getMainManuals()
              this.getManuals()
              this.$swal({
                title: '¡Listo!',
                text: 'El manual fue eliminado',
                icon: 'success',
              })
            })
          }
        })
      },

      async createContent() {
        if (!this.content.version || !this.content.version.id) {
          this.$swal({
            title: '',
            text: 'Selecciona una versión',
            icon: 'info',
          })
        } else {
          this.content.active = this.content.active ? 1 : 0
          this.config.headers = {
            'Content-Type': 'multipart/form-data',
            'X-Requested-With': 'XMLHttpRequest',
            Authorization: this.$session.get('tokenSession')
              ? 'Bearer ' + decryptData(this.$session.get('tokenSession'))
              : 'Bearer ',
          }

          const formData = new FormData()
          formData.append('user_id', decryptData(this.$session.get('userId')))
          formData.append('name', this.content.name)
          formData.append('active', this.content.active)
          formData.append('manual_id', this.selected.id)
          if (this.content.version && this.content.version.id) {
            formData.append('version_id', this.content.version.id)
          }
          if (
            this.content.file &&
            this.content.file !== '' &&
            this.content.file !== undefined
          ) {
            formData.append('file', this.content.file)
          }
          formData.append('fileType', 'document')
          if (this.content.format && this.content.format.id) {
            formData.append('format', this.content.format.id)
          }
          if (this.content.url && this.content.url !== '') {
            formData.append('url', this.content.url)
          }
          formData.append('date', this.content.date)
          this.$axios.post('contents', formData, this.config).then(() => {
            this.selected.contents.push(this.content)
            this.getMainManuals()
            this.$modal.hide('contentModal')
          })
        }
      },

      async saveContent() {
        if (!this.content.version || !this.content.version.id) {
          this.$swal({
            title: '',
            text: 'Selecciona una versión',
            icon: 'info',
          })
        } else {
          this.content.active = this.content.active ? 1 : 0
          this.config.headers = {
            'Content-Type': 'multipart/form-data',
            'X-Requested-With': 'XMLHttpRequest',
            Authorization: this.$session.get('tokenSession')
              ? 'Bearer ' + decryptData(this.$session.get('tokenSession'))
              : 'Bearer ',
          }

          const formData = new FormData()
          formData.append('user_id', decryptData(this.$session.get('userId')))
          formData.append('name', this.content.name)
          formData.append('active', this.content.active)
          formData.append('manual_id', this.selected.id)
          if (this.content.version && this.content.version.id) {
            formData.append('version_id', this.content.version.id)
          }
          if (
            this.content.file &&
            this.content.file !== '' &&
            this.content.file !== undefined
          ) {
            formData.append('file', this.content.file)
            formData.append('fileId', this.content.file.id)
          }
          formData.append('fileType', 'document')
          if (this.content.format && this.content.format.id) {
            formData.append('format', this.content.format.id)
          }
          if (this.content.url && this.content.url !== '') {
            formData.append('url', this.content.url)
          }
          formData.append('date', this.content.date)
          formData.append('_method', 'patch')
          this.$axios
            .post('contents/' + this.content.id, formData, this.config)
            .then(() => {
              this.selected.contents.push(this.content)
              location.reload()
              // this.getMainManuals()
              this.$modal.hide('contentModal')
            })
        }
      },

      async deleteContent(item) {
        this.$swal({
          title: '¿Estás seguro?',
          text: 'Eliminarás este contenido de forma permanente',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonText: 'Cancelar',
          confirmButtonText: 'Si, eliminar',
        }).then((result) => {
          if (result.isConfirmed) {
            this.$axios.delete('contents/' + item.id).then(() => {
              this.$modal.hide('contentModal')
              this.getMainManuals()
              this.$swal({
                title: '¡Listo!',
                text: `El contenido fue eliminado`,
                icon: 'success',
              })
            })
          }
        })
      },
    },
  }
</script>
<style lang="scss">
  @import '@/sass/cruds/_crudStyles.scss';
  .v-application--is-ltr .v-treeview-node__content {
    margin-left: 0px;
  }
  .manuals-tree {
    .treeview {
      @media screen and (max-width: 576px,) {
        max-height: 400px;
        overflow-y: auto;
      }
    }
    .v-treeview-node__root {
      padding: 0px;
      justify-content: flex-end;
    }
    .v-treeview-node__label {
      cursor: pointer;
    }
    .v-treeview-node__level {
      width: 20px;
    }
    .v-subheader {
      height: auto;
    }
  }
</style>
