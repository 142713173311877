<template>
  <div class="vuse-content-wrapper">
    <v-row no-gutters justify="space-between" align="center">
      <smart-breadcrumbs :items="navigationElements" />
      <help class="help--breadcrumb" :helpId="45" />
    </v-row>
    <v-col cols="12" class="d-flex align-center" v-if="showTypeSelector"
      ><span class="caption mr-1">Formato</span
      ><multiselect
        track-by="id"
        label="name"
        placeholder="Seleccionar"
        deselect-label="Remover"
          select-label="Seleccionar"
          selected-label="Seleccionado"
        v-model="selectedType"
        :allow-empty="false"
        :options="types"
        :loading="loadingTypes"
        :multiple="false"
        :close-on-select="true"
        :clear-on-select="true"
        :preserve-search="false"
        class=""
        style="max-width: 200px"
    /></v-col>
    <v-card>
      <manuals-tree :typeId="typeId" :key="typeIndex" />
    </v-card>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import { decryptData } from '@/utils/encryption'
  import ManualsTree from '@/components/HumanTalent/ManualsTree'

  export default {
    components: {
      ManualsTree,
    },
    data() {
      return {
        typeIndex: 0,
        types: [],
        selectedType: null,
        loadingTypes: false,
        typeId: 1,
        navigationElements: [
          {
            text: 'Talento Humano',
            disabled: false,
            query: null,
            params: null,
            name: 'administrative/human-talent/AdminPanel',
            href: '/administrative/human-talent/admin-panel',
          },
          {
            text: 'Inventario de Manuales',
            disabled: true,
          },
        ],
      }
    },
    computed: {
      ...mapGetters(['userCurrentCompany']),
      company: {
        get() {
          return decryptData(this.userCurrentCompany)
        },
        set(newValue) {},
      },
      companies() {
        if (
          this.$session.get('companies') &&
          decryptData(this.$session.get('companies'))
        ) {
          return decryptData(this.$session.get('companies'))
        } else {
          return []
        }
      },
      showTypeSelector() {
        if (this.companies.length > 0) {
          return true
        } else {
          return false
        }
      },
    },
    created() {
      this.getTypes()
    },
    watch: {
      selectedType(newValue) {
        if (newValue) {
          this.typeId = newValue.id
          this.typeIndex++
        }
      },
    },
    methods: {
      getTypes() {
        this.loadingTypes = true
        this.$axios.get('types?limit=20').then((response) => {
          this.types = response.data
          this.selectedType = this.types.find((type) => type.id === this.company.type_id)
          this.typeId = this.selectedType.id
          this.loadingTypes = false
        })
      },
    },
  }
</script>
<style lang="scss"></style>
